import React, { useCallback, useMemo } from "react";
import days from "dayjs";
import { ParentMediaTypeNames } from "@constants/App";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import { deadlineOfDraft } from "@lib/util/date";
import { View, Text, Wrapper } from "@components/atoms/Themed";
import { StyleSheet, TouchableOpacity } from "react-native";
import {
  ChatActionPost$key,
  ParentMediaType,
} from "@generated/ChatActionPost.graphql";
import { ChatActionBank$key } from "@generated/ChatActionBank.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import { navigate } from "@navigation/navigate";

const chatAction = graphql`
  fragment ChatActionPost on Post {
    id
    status
    draftedAt
    campaign {
      prType
      parentMediaType
      socialNetwork {
        postStartOn
        postEndOn
        castEndAt
      }
    }
  }
`;

const bankQuery = graphql`
  fragment ChatActionBank on Influencer {
    bankAccount {
      id
    }
  }
`;

type Props = {
  /** キャンペーン詳細 */
  postFragment: ChatActionPost$key;
  /** 口座情報 */
  bankFragment: ChatActionBank$key;
  /** 商品受け取り報告でアクションボタンをクリックした時のイベントハンドラ */
  openProductRecieve: () => void;
};

type BarContent = {
  color: string;
  text: string;
  action?: string;
};

export default function ChatAction({
  postFragment,
  bankFragment,
  openProductRecieve,
}: Props) {
  const data = useFragment(chatAction, postFragment);
  const { bankAccount } = useFragment(bankQuery, bankFragment);

  const publishText = useMemo<string>(() => {
    const parentMediaType = data.campaign.parentMediaType as ParentMediaType;
    if (parentMediaType === "cast") {
      return `${days(data.campaign.socialNetwork.castEndAt).format(
        "M月D日"
      )}になったら完了報告を行ってください`;
    }
    if (parentMediaType === "other") {
      return `${days(data.campaign.socialNetwork.postStartOn).format(
        "M月D日"
      )}以降に完了報告を行ってください`;
    }
    return `${days(data.campaign.socialNetwork.postStartOn).format(
      "M月D日"
    )}以降に${
      ParentMediaTypeNames[parentMediaType]
    }に投稿して完了報告を行ってください`;
  }, [data]);

  const barContent = useMemo<BarContent>(() => {
    const draftDate = deadlineOfDraft(
      data.draftedAt,
      data.campaign.socialNetwork.postStartOn
    );
    switch (data.status) {
      case "wait_product_shipped":
        return {
          color: Colors.gray,
          text: "商品を発送準備中です",
        };
      case "product_shipped":
        return {
          color: Colors.green,
          text: "商品が届いたら報告してください",
          action: "報告する",
        };
      case "wait_visitation":
        return {
          color: Colors.green,
          text: "指定の場所に訪問してください",
          action: "チケット確認",
        };
      case "draft":
        return {
          color: Colors.green,
          text:
            draftDate !== null
              ? `${draftDate.format("M月D日")}までに下書きを作成してください`
              : "下書きを作成してください",
          action: "作成する",
        };
      case "admin_rejected":
      case "agency_rejected":
      case "draft_with_reject":
        return {
          color: Colors.green,
          text: "下書きを修正してください",
          action: "修正する",
        };
      case "wait_admin_review":
      case "wait_agency_review":
        return {
          color: Colors.gray,
          text: "下書きレビュー中です",
          action: "確認する",
        };
      case "wait_publish":
        return {
          color: Colors.green,
          text: publishText,
          action: "報告する",
        };
      case "wait_agency_confirm_report":
        return {
          color: Colors.gray,
          text:
            data.campaign.parentMediaType === "cast" ||
            data.campaign.parentMediaType === "other"
              ? "完了報告を確認中です"
              : `${
                  ParentMediaTypeNames[data.campaign.parentMediaType]
                }の投稿を確認中です`,
          action: "確認する",
        };
      case "finished":
      default:
        if (
          data.status === "finished" &&
          bankAccount === null &&
          data.campaign.prType === "rewarded"
        ) {
          return {
            color: Colors.orange,
            text: "報酬を受け取る銀行口座を登録してください",
            action: "登録する",
          };
        }
        return {
          color: Colors.gray,
          text: "お疲れ様でした",
        };
    }
  }, [data, bankAccount, publishText]);

  // ステータスに応じてアクションを分岐する
  const actionHandler = useCallback(() => {
    if (
      data.status === "finished" &&
      bankAccount === null &&
      data.campaign.prType === "rewarded"
    ) {
      navigate("BankEdit");
      return;
    }

    switch (data.status) {
      case "product_shipped":
        openProductRecieve();
        break;
      case "wait_visitation":
        navigate("Ticket", { id: data.id });
        break;
      case "draft":
      case "admin_rejected":
      case "agency_rejected":
      case "draft_with_reject":
      case "wait_admin_review":
      case "wait_agency_review":
        navigate("PostDraft", { id: data.id });
        break;
      case "wait_publish":
      case "wait_agency_confirm_report":
        navigate("PostReport", { id: data.id });
        break;
      default:
    }
  }, [data, bankAccount, openProductRecieve]);

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: barContent.color,
        },
      ]}
    >
      <Wrapper>
        <View style={styles.content}>
          <View style={styles.guide}>
            <Text style={styles.status}>{barContent.text}</Text>
          </View>
          {barContent.action !== undefined && (
            <TouchableOpacity onPress={actionHandler} style={styles.action}>
              <Text style={styles.actionText}>{barContent.action}</Text>
            </TouchableOpacity>
          )}
        </View>
      </Wrapper>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  content: {
    height: 50,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  guide: {
    maxWidth: "75%",
  },
  status: {
    ...Fonts.lb100,
    color: Colors.white,
  },
  deadline: {
    ...Fonts.lr130,
    color: Colors.white,
  },
  action: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  actionText: {
    ...Fonts.lb100,
    color: Colors.white,
  },
});
