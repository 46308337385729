import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import { Text, View, Wrapper, Card } from "@components/atoms/Themed";
import Spacer from "@components/atoms/Spacer";
import LinkableText from "@components/atoms/LinkableText";
import Fonts from "@constants/Fonts";

export default function TermsOfUse() {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Wrapper>
        <Card style={styles.content}>
          <Text style={styles.text}>
            本利用規約（以下「本規約」といいます。）は、株式会社INFLUENCER
            BANK（以下「当社」といいます。）が提供する本サービス（第１条に定義）をご利用いただく際の条件及び当社と利用者（第１条に定義）の皆様との間の権利義務関係を定めています。
            利用者の皆様は、本規約の全文をお読みいただき、内容につきご承諾いただいたうえで、本サービスをご利用ください。
          </Text>
          <Spacer height={16} />
          <Text style={styles.section}>第1条（定義）</Text>
          <Spacer height={16} />
          <View style={styles.indent}>
            <Text style={styles.text}>
              本規約において使用する用語の定義は、次に定めるとおりとします。
            </Text>

            <Spacer height={8} />

            <View style={styles.indent}>
              <Text style={styles.text}>1. 本サービス</Text>
              <Text style={styles.text}>
                発注者とインフルエンサーをマッチングさせるサービス及びそれに付随して提供されるサービスの総称
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>2. 本契約</Text>
              <Text style={styles.text}>
                本規約を内容とする本サービスの利用契約
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>3. 登録手続き</Text>
              <Text style={styles.text}>
                本サービスを利用するために必要となる当社所定の手続き
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>4. 登録情報</Text>
              <Text style={styles.text}>
                登録手続きにおいて利用者が当社に提供した利用者の属性、個人情報その他すべての情報
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>5. 個人情報</Text>
              <Text style={styles.text}>
                個人情報の保護に関する法律第２条第１項所定の情報
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>6. 発注者</Text>
              <Text style={styles.text}>
                本サービスを通じてインフルエンサーに業務を委託し、又は委託しようとする個人又は法人
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>7. インフルエンサー</Text>
              <Text style={styles.text}>
                本サービスを通じて、発注者から業務を受託し、又は受託しようとする個人又は法人
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>8. 利用者</Text>
              <Text style={styles.text}>発注者及びインフルエンサーの総称</Text>
              <Spacer height={8} />

              <Text style={styles.text}>9. 募集情報</Text>
              <Text style={styles.text}>
                発注者が当社に提供した業務委託案件の募集に係る情報
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>10. 対象契約</Text>
              <Text style={styles.text}>
                本サービスを通じてインフルエンサーと発注者との間で締結された業務委託契約その他一切の契約
              </Text>
              <Spacer height={8} />

              <Text style={styles.text}>11. 本件業務</Text>
              <Text style={styles.text}>
                対象契約に基づいてインフルエンサーが受託する業務
              </Text>
            </View>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第2条（総則）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              本規約は、本サービスの提供条件及び本サービスの利用に関する当社と利用者との間の権利義務関係を定めることを目的とし、利用者と当社との間の本サービスの利用に関わる一切の関係に適用されます。
            </Text>
            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              利用者は、本サービスへの登録手続きを行うことにより、本サービスの提供を受けることができます。なお、本サービスは、雇用契約のあっせんを行うものではありません。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第3条（本規約）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              当社は、利用者に対してあらかじめ通知することなく、また、承諾を得ることなく、本規約の内容を変更できるものとします。本規約の全部又は一部が変更された場合、
              当社が別に定める場合を除き、変更後の利用者による本サービスの利用については変更後の本規約が適用されるものとし、当該利用により、利用者は当該変更に同意したものとみなされます。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              当社が当社ウェブサイトやその他の方法で定めるルールは、本規約の一部を構成するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              本規約のいずれかの部分が無効である場合でも、本規約全体の有効性には影響がないものとし、かかる無効の部分については、当該部分の趣旨に最も近い有効な規定を無効な部分と置き換えて適用し、
              又は当該部分の趣旨に最も近い有効な規定となるよう合理的な解釈を加えて適用するものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第4条（本サービスの登録手続き）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              利用者となろうとする者は、必ず本規約を承諾の上、当社所定の方法により、当社の要求する事項を記載又は記録の上、本サービスの利用に必要な所定の登録手続きを行うものとします。
              本規約を承諾いただけない場合には、本サービスを利用することはできません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              当社は、利用者となろうとする者が以下の各号のいずれかの事由に該当する場合は、登録及び本サービスの利用の全部又は一部を拒否することがあります。
              また、当社は、その理由について一切開示義務を負わず、法的構成の如何を問わず損害賠償責任を負いません。
            </Text>

            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）当社に提供した登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）未成年者であって法定代理人の包括的な同意を得ていない場合、成年被後見人、被保佐人又は被補助人等である場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （3）暴力団、暴力団員、右翼団体、反社会勢力、その他これに準ずる者（以下「反社会的勢力等」といいます。）である、又は資金提供その他を通じて反社会勢力等の維持、
                運営もしくは経営に協力もしくは関与等反社会勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （4）過去当社との契約に違反した者又はその関係者であると当社が判断した場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （5）その他、当社が本サービスの利用を適当でないと判断した場合
              </Text>
            </View>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              当社は、利用者登録手続きを完了したときは、利用者に対し登録手続きの完了を通知するものとし、当該通知の発信をもって、利用者と当社との間で本契約が成立するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              芸能事務所等に所属する方がインフルエンサーとして登録する際は、必ず事前に芸能事務所等と相談し、登録について承諾を得なければ、
              インフルエンサーとして登録する事はできません。インフルエンサーが登録後に芸能事務所等に所属することとなった場合、インフルエンサーは芸能事務所等と相談し、
              インフルエンサーとしての登録について事務所の承諾を得るものとします。
              当社は、利用者と所属芸能事務所等との間の契約やトラブル等に関して、一切の責任を負いません。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第5条（登録情報の取扱い）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1. 登録情報は当社が所有するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              利用者は、登録する登録情報の全ての項目に関して、真実かつ正確な情報を登録しなければならず、虚偽の情報を登録してはならないものとします。
              登録情報に変更が生じた場合あるいは誤りが発見された場合は、利用者は、速やかにその旨当社に通知の上、これを最新かつ正確な情報に修正しなければならないものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              当社が必要と認めた場合、メールその他の方法で利用者に登録情報の確認を行うことができるものとし、利用者は誠実にこれに応じるものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第6条（登録情報の修正・削除）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              当社は、以下のいずれかに該当すると判断した場合は、利用者の承諾なく、登録情報を修正し又は削除できるものとします。
            </Text>
            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）登録情報に関して利用者が本規約に違反したと当社が判断した場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）登録情報が事実と異なると当社が判断した場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （3）その他、本サービスの目的に鑑み当社が不適当と判断した場合
              </Text>
            </View>
            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              当社は、前項の修正又は削除により利用者に不利益、損害が発生しても、一切の責任を負わないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第7条（登録情報に係る権利）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              当社は、利用者の登録情報（個人情報を含ます）を、本サービスの提供に必要な範囲で無償で使用（複製、改変、編集、頒布、公表等を含みますが、これらに限られません。）できるものとし、
              利用者は予めこれに同意するものとします。この場合において、利用者は、当社又は当社が指定する第三者に対して著作者人格権その他の人格権を行使しないものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              本サービスの提供上必要と認められる場合には、必要な範囲において、当社から、発注者、インフルエンサー又は当社の委託先（配送業者等）に対し登録情報を提供することがあり、
              利用者は予めこれに同意するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              当社は、本サービスをより良いものにするために、個人を識別又は特定できない態様と範囲で、統計的な情報として登録情報等を集計し、
              当該統計的情報を第三者に提供することができるものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              本サービスは以下の利用規約・プライバシーポリシー等に準じて、各APIサービスを使用しています。利用者はこれらの利用規約・
              プライバシーポリシー等に同意した上で本サービスを使用するものとします。
            </Text>

            <Spacer height={8} />

            <View style={styles.indent}>
              <Text style={styles.text}>
                YouTube Terms of Service（YouTubeの利用規約）
              </Text>
              <Spacer height={8} />
              <LinkableText style={styles.text}>
                https://www.youtube.com/static?template=terms&hl=ja&gl=JP
              </LinkableText>

              <Spacer height={8} />
              <Text style={styles.text}>
                YouTube API Services Terms of Service
              </Text>
              <Spacer height={8} />
              <LinkableText style={styles.text}>
                https://developers.google.com/youtube/terms/api-services-terms-of-service-apac
              </LinkableText>

              <Spacer height={8} />
              <Text style={styles.text}>Google プライバシーポリシー</Text>
              <Spacer height={8} />
              <LinkableText style={styles.text}>
                https://policies.google.com/privacy?hl=ja
              </LinkableText>

              <Spacer height={8} />
              <Text style={styles.text}>
                Googleセキュリティセッティングページ
              </Text>
              <Spacer height={8} />
              <LinkableText style={styles.text}>
                https://security.google.com/settings/security/permissions
              </LinkableText>

              <Spacer height={8} />
              <Text style={styles.text}>Xサービス利用規約</Text>
              <Spacer height={8} />
              <LinkableText style={styles.text}>
                https://twitter.com/ja/tos
              </LinkableText>

              <Spacer height={8} />
              <Text style={styles.text}>Instagramサービス利用規約</Text>
              <Spacer height={8} />
              <LinkableText style={styles.text}>
                https://www.facebook.com/help/instagram/581066165581870
              </LinkableText>
            </View>

            <Spacer height={8} />
            <LinkableText style={styles.text}>
              5.
              本規約に定めるほか、当社は利用者の登録情報を「プライバシーポリシー」（https://vectorinc.co.jp/privacy）に従って取り扱うものとします。
            </LinkableText>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第8条（ID・パスワードの管理）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              利用者は、本サービスにおいて登録したID及びパスワードについて、自己の責任の下で厳重に管理し、ID及びパスワードの盗用を防止する措置を自ら講じるものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              利用者は、登録したID及びパスワードについて、第三者による利用や第三者への貸与・譲渡等の行為を行ってはならないものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              ID及びパスワードの管理不十分、使用上の過誤、第三者の使用等により被った損害は利用者が責任を負うものとし、
              当社は、かかる利用者の損害について一切の責任を負わないものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              利用者は、ID及びパスワードの盗用や第三者による使用が判明した場合、直ちにその旨を当社に通知し、当社からの指示に従うものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第9条（本サービスの内容）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              本サービスは、発注者とインフルエンサーが業務委託契約を締結することを目的とするものであり、当社は、当該契約の当事者とはなりません。
              いずれの利用者も、自らの判断と責任において対象契約を締結するものとし、当社は利用者間の契約（契約不成立を含みます）には一切関与せず、
              また利用者間においてトラブル等が生じた場合にも一切の責任を負いません。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>
            第10条（利用者間の連絡、直接契約の禁止）
          </Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              利用者間の連絡は、原則として本サービス内において行うものとします。ただし、当社が事前に承諾した場合はこの限りではありません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              利用者（利用者の親会社、子会社、関連会社、役員を含みます。以下本項において同じ。）は、本契約期間中及び終了後３年間は、本サービスを通じて知った情報によって、本サービスを介さずに他の利用者と直接又は間接に取引を行ってはならないものとします。当該期間中に発注者とインフルエンサーとの間で契約が締結された場合、本サービスに基づく契約締結とみなし、規定に則り本サービスの利用料等を支払うものとします
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第11条（募集情報）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              発注者は、業務の依頼に際して、当社所定の方法により、募集情報を提供するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              発注者は、募集情報を提供する場合には、企業情報、募集条件及び募集内容等、当社所定の事項及びその他の事項を明確に通知するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3. 発注者は、以下に定める募集情報の提供はできません。
            </Text>

            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）発注者の従業員としての募集など、雇用契約の成立を内容とする募集情報
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）法令又は公序良俗に違反すると当社が判断する募集情報
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （3）その他、本サービスの目的に鑑み当社が不適当と判断する募集情報
              </Text>
            </View>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              当社は、発注者が提供した募集情報の内容が本規約を遵守するものか、不適切な内容が記載されていないか等を事前（及び事後）に審査することができるものとします。
              なお、当社は、処理すべき審査業務が大量に発生した場合等やむを得ない場合には、発注者があらかじめ希望する日時までに審査の処理を行えない場合があり、
              その結果、インフルエンサーへの募集情報の提供が遅れる場合がありますが、発注者はこの点をあらかじめ承諾し、損害賠償請求その他一切異議を述べないものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              5.
              募集情報が本規約に反するなど不適切であると当社が判断した場合には、当社は、なんらの責任を負うことなく、インフルエンサーへの募集情報提供を取り消すことができるものとします。
              この場合、発注者は、当社の指示に従い募集情報を修正しなければならず、修正後の募集情報についても前各項の規定が準用されます。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              6.
              発注者は、当社に提供した募集情報に変更が生じた場合には、直ちに当該変更内容を通知させるものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              7.
              前各項の規定にかかわらず、当社は、発注者が当社に提供し、当社がインフルエンサーへ提供した募集情報について、適法性、適切性、その他本規約を遵守すること等を保証せず、一切責任を負いません。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第12条（対象契約の成立）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              発注者は、募集情報に定める期間内に、前条の依頼に応募してきたインフルエンサーの採用又は不採用を決定した上、採用の場合には本サービスにおいて当該インフルエンサーにその旨を連絡するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              前項に定める期間内に発注者からインフルエンサーに対して採用の連絡がなされなかった場合には、発注者は、当該応募の不採用を決定したものとみなします。
              この場合、インフルエンサーは、当社及び発注者に対し、当該応募の不採用について、理由の開示の請求、異議を申立てることはできないものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              発注者が特定のインフルエンサーを採用した時点で、当該発注者とインフルエンサーとの間で対象契約が成立するものとします。
              なお、利用者は、対象契約成立後に契約内容の変更や解除はできないものとします。ただし、相手方の同意がある場合にはこの限りではありません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              対象契約の成立に際して、インフルエンサーと発注者の間で対象契約の詳細について取決めを行う必要がある場合は、当事者間で別途合意するものとし、
              当社は、その合意の存否及び内容について関知せず、その結果生じた損害について一切の責任を負わないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第13条（本件業務の中断・停止）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              本件業務の完了前に、発注者又はインフルエンサーの都合により、本件業務の中断・停止を希望する場合は、発注者とインフルエンサーの間で協議のうえ、
              当事者間が合意した場合に限り、本件業務の中断及び停止ができるものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              前項に基づき、本件業務を中断・停止した場合には、発注者は当社に対して、直ちにその旨を報告しなければならないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>
            第14条（成果物の納品・業務の報告・検収）
          </Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              インフルエンサーは、発注者に対し、対象契約において成果物のある場合には対象契約に定める期日までに本サービスにおいて本件業務の成果物を納品するものとし、
              対象契約において成果物のない場合には対象契約に定める期日までに本サービスにおいて業務完了の報告を行うものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              発注者は、本件業務の成果物（成果物のない場合には報告された業務結果）が依頼内容に合致しているか審査し、
              ３営業日以内に、当社に対して検収結果を通知する義務を負うものとします。なお、当該成果物の納品後（成果物のない場合には業務完了の報告後）
              ３営業日以内に、発注者が検収結果を通知しない場合、当該成果物（成果物のない場合には当該業務）は、発注者の検収に合格したものとみなします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              前項の検収の結果、発注者の検収に合格した時点で本件業務は完了するものとします。なお、本件業務に複数の業務が含まれる場合は、
              インフルエンサーが全ての業務を完了し、すべての業務について検収が完了した時点
              （本件業務に成果物の作成と媒体への掲載が含まれる場合は、検収に合格した成果物をインフルエンサーが対象契約に従い掲載媒体に投稿し、
              発注者に対し当該投稿を完了した旨の報告をし、発注者が投稿完了を確認した時点）で、本件業務は完了するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              当社は、インフルエンサーに対し、第2項の検収結果について、何らの説明責任、損害賠償その他の責任を負わないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第15条（支払い）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              発注者とインフルエンサーとの間で対象契約が締結された場合、インフルエンサーは当社に対し、本サービス利用料として、別途当社の定める料金を負担するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              対象契約の報酬の支払時期及び方法については、以下の各号によるものとします。なお、発注者のインフルエンサーに対する報酬の支払事務については、
              当社がインフルエンサーに代わり当該報酬を受領し、それを当社がインフルエンサーに引渡すことにより行われるものとします。
              よって、当社において発注者から当社への支払いが確認できない場合には、当社はインフルエンサーに対し報酬の支払い義務を負いません。
            </Text>

            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）本件業務の履行が完了した時点で発注者は、当社に対し、対象契約に定める報酬の払込みをするものとします。
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）発注者から当社に対し、報酬の払込みが行われた場合、当社はインフルエンサーに対して、発注者から払い込まれた金額から本サービス利用料及び
                インフルエンサーへの振込手数料を控除した金額を、対象取引完了の翌月末日までに、インフルエンサーが指定する銀行口座に振り込む支払うものとします。
              </Text>
            </View>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              前２項の定めにかかわらず、対象契約においてインフルエンサーに対し金銭報酬の発生しない場合は、本サービス利用料は発注者が負担するものとし、
              この場合の本サービス利用料及び発注者から当社への支払い条件は別途当社が定めるものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              当社に対する発注者の振込手数料については、発注者が負担するものとし、インフルエンサーに対する当社の振込手数料については、
              インフルエンサーが負担するものとします。なお、当社からインフルエンサーに対して支払う金額が振込手数料以下となる場合には、
              相殺により当社はインフルエンサーに対しての支払い義務を負わないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第16条（当社の義務及び責任）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              当社は、利用者に対し、本サービスの利用により業務委託契約その他利用者との間の何らかの契約の成立を保証するものではありません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              当社は、本サービスにエラーその他の不具合がないこと、その他本サービスの提供にかかるインフラ、システム等に瑕疵が存在しないこと等につき保証するものではありません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              当社は、本サービスにおいて提供する情報の完全性、正確性、有用性・第三者の権利の非侵害等に関していかなる保証も行うものではありません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              当社は、発注者の依頼する内容、インフルエンサーの選定、インフルエンサーによる本件業務の遂行並びにその成果物等について、
              それらの内容・完全性・適法性・正確性・有用性・第三者の権利の非侵害等の確認及び保証を行うものではありません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              5.
              当社は、利用者が本サービスを利用したことにより利用者又はその関係者に生じた一切の損害につき、当社に故意又は重大な過失がない
              限り責任を負わないものとします｡但し､当社が責任を負う場合であっても､当社の責任は、当社の責に帰すべき事由に起因して
              現実に発生した直接かつ通常の範囲の損害に限られるものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第17条（利用者の義務及び責任）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              利用者は、自らの責任に基づき本サービスを利用するものとし、本サービスの利用に関する一切の責任を負うものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              利用者は、登録手続きを適法かつ正当に行う権限を有していることを保証するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              利用者は、当社に対し第三者に関する情報を提供する場合、事前に当該第三者から必要な承諾・許諾等を得るものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              利用者は、本サービスの利用に当たり、以下の各号の規定を遵守するほか、本サービスの利用に関連してなされる行為及びその結果が、
              法令及び本規約に適合し、かつ当社及び他の利用者を含む第三者の権利を侵害しないことを保証するものとします。
            </Text>

            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）対象契約によってインフルエンサーに支払われる報酬について、発注者が源泉徴収をする義務があるときは、
                発注者は源泉徴収税の納付、支払調書の交付等の義務を履行するものとします。
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）対象契約が、下請代金支払遅延等防止法の対象となるときは、親事業者となる発注者は、同法を遵守するものとします。
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （3）対象契約の履行において、利用者は、「景品表示法」、「医薬品、医療機器等の品質、有効性及び安全性の確保等に関する法律」その他広告表示
                関係法令及び業界ガイドライン等を遵守するものとします。
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （4）利用者は、本サービスにより取得する個人情報については、個人情報の保護に関する法律及びそのガイドライン等に従って、
                適法かつ適正に収集、利用、管理及び保管又は第三者への提供を行うものとします。
              </Text>
            </View>

            <Spacer height={8} />
            <Text style={styles.text}>
              5.
              利用者は、本契約期間中はもちろん、本契約終了後も、その責任と費用において、自身が行った対象契約により生じる義務を、本規約に従い履行するものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              6.
              利用者は、本サービスの利用に伴い第三者に対して損害を与えた場合、又は第三者から異議、クレームその他請求がなされた場合、自己の費用と負担において、
              これを解決するものとします。また、利用者が本サービスの利用に伴って第三者から損害を被った場合、又は第三者に対して異議、クレームその他請求を行う場合も同様とします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第18条（禁止行為）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              利用者は、本サービスの利用に際して、故意又は過失を問わず、以下に定める行為（これらに該当するおそれのある行為や誘発する行為を含みます。）を行ってはなりません。
            </Text>

            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）一人の利用者が当社の許可なく複数のメールアドレス等を登録して重複して利用者登録を行う行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）利用者登録を停止又は無効にされた利用者に代わり又は当該利用者のために利用者登録をする行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （3）当社、第三者（他の利用者を含みます。以下本条において同じ）を差別若しくは誹謗中傷し、又は第三者の名誉、品位若しくは社会的信用を毀損する行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （4）アカウントをのっ取る等して、当社又は第三者になりすます行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （5）当社、第三者に対し、無断で、広告・宣伝・勧誘等のメッセージ若しくは、社会通念上のコンプライアンスに違反するメッセージを送信する
                行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （6）当社、第三者の著作権、商標権等の知的財産権を侵害する行為、又は侵害するおそれのある行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （7）当社、第三者の財産、プライバシー若しくは肖像権を侵害する行為又は侵害するおそれのある行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （8）当社、第三者に関する個人情報や営業上の情報を、偽りその他不正な手段を用いて収集、取得する行為、又はそれに類似する行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （9）個人情報や営業上の情報を本人の同意なく第三者に開示、提供する行為、又はそれに類似する行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （10）虚偽の情報を意図的に提供する行為、又はそれに類似する行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （11）
                本サービスに基づく業務委託以外を目的とする営業活動等、本サービスの目的外利用行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （12）
                法令に基づき監督官庁等への届出、許認可の取得等の手続が義務づけられている場合に、当該手続を履行せずに本サービスを利用する行為その他当該法令に違反する又は違反するおそれのある行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （13）
                当社への長時間の架電、同様の問い合わせの繰り返し、義務や理由のない行為の強要、当社ウェブサイトのサーバ等への攻撃等、当社の業務に支障を来たす行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （14））
                特定の商品又はサービスについて実際のものより優良若しくは有利と誤認させるおそれのある行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （15）
                利用者間で成立した対象契約に違反する行為、発注者の依頼内容と明らかに異なる成果物の提案や、依頼とは別の目的で作成された成果物を意図的に提案として行う行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （16）
                「景品表示法」、「医薬品、医療機器等の品質、有効性及び安全性の確保等に関する法律」その他広告表示
                関係法令及び業界ガイドライン等に違反する行為及び違反するおそれのある行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （17）
                上記各号の他、本規約に違反する行為、法令に違反する行為、犯罪行為、犯罪を助長する行為、公序良俗に違反する行為
                （暴力を助長し、誘発するおそれのある情報又は残虐な映像を送信又は表示する行為や心中の仲間を募る行為等を含む）その他迷惑行為
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （18） その他当社が、不適当若しくは適正ではないと判断した行為
              </Text>
            </View>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              利用者が前項に違反し、又はその恐れがあると当社が判断した場合、当社は、本サービスの利用の停止、登録の取り消し、本契約の即時無催告解除その他当社が相当と認める措置をとることができるものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              当社は、前項の措置をとったこと、又は前項の措置をとらないことに関し、一切の責任を負いません。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>
            第19条（本サービスに係る権利の帰属）
          </Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              本サービスに関する特許権、実用新案権、商標権、著作権（著作権法第２７条及び第２８条に定める権利を含みます。以下同じ）、ノウハウその他一切の権利（これらを受ける権利を含みます。）は､当社に帰属します。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              発注者は、当社の事前の書面による承諾を得た場合を除き、本サービスに関する情報を、他の媒体（ホームページを含みますが、これに限られません。）に転載・流用してはなりません。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>
            第20条（対象契約の成果物等に関する知的財産権及びその利用）
          </Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              本サービスを通じてインフルエンサーが発注者に対して納品した成果物に関する著作権等の知的財産権は、発注者又は第三者が保有する権利を除き、
              インフルエンサーに帰属するものとします。ただし、インフルエンサーと発注者との間で別段の合意がある場合にはこの限りではありません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              インフルエンサーは、第三者の保有する知的財産権その他の権利を成果物に利用する場合、当該第三者との間で必要となる権利処理を行うものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              発注者は、インフルエンサーとの間で成立した対象契約の範囲を超えて成果物を使用してはならないものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              インフルエンサーが納品した成果物、利用者がプロフィールとして掲載又は投稿した画像、テキスト等を含む、
              本サービスにおいて利用者が作成・登録・提供・掲載した一切の画像、テキスト、プログラム等について、当社は、本サービスや販促物に掲載する等、
              当社が本サービスの広告宣伝等のために必要な範囲で、無償かつ永続的に利用できるものとします。ただし、利用者から利用停止の申し出があった場合は、
              かかる利用を中止するものとします。また、かかる利用に際して、利用者は、当社及び当社が指定する第三者に対して、著作者人格権を行使しないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第21条（退会）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              利用者は、当社の定める手続・方法に従い、本サービスの利用を終了することができます。ただし、当該利用者が次の各号に定める状況にある間は退会できないものとします。
            </Text>

            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）自らが受発注者となって成立した取引の業務が終了していない場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）自らが受発注者となって成立した取引の決済手続が完了していない場合
              </Text>
            </View>
            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              月額課金制のサービスについて解約する場合には、月の途中で解約する場合であっても当該月の月額料金は減額されないものとし、
              また、当社は利用者から受領した月額料金について一切の返還義務を負わないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第22条（本サービスの解除等）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              利用者が以下のいずれかに該当すると当社が判断した場合、当社は、利用者に事前に通知することなく、本サービスの提供の中断、
              本契約の解除、登録抹消等の必要な措置をとることができるものとします。
            </Text>

            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）本規約に違反したと当社が判断したとき
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）当社からの問い合わせその他の回答を求める連絡に対して３０日間以上応答がない場合（当社からの通知が届かない場合を含みます）
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （3）本サービスの利用料金の支払いを遅滞又は拒否した場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （4）差押え、仮差押え、競売、破産手続開始又は民事再生手続開始等の申立てがあった場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （5）手形又は小切手の不渡りを出した場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （6）支払停止又は支払不能に陥った場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>（7）合併によらず解散した場合</Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （8）前各号のほか、当社が合理的に必要と判断した場合
              </Text>
            </View>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              前項に基づいて場合、当社が本契約の解除を決定した時点で、当該利用者が本サービスに関連して有する権利は全て失われるものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              3.
              当社は、本条第１項の措置をとったこと、又は本条第１項の措置をとらないこと、及び前項の権利喪失に関し、一切の責任を負わず、質問・苦情等も一切受け付けません。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              4.
              当社は、本条第１項の措置をとったことにより利用者又は第三者が被る損害について、一切の責任を負わないものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              5.
              当社は、本条第１項の措置をとった場合であっても、利用者により既に支払われた本サービスの利用料等について、一切の返還義務を負わないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第23条（本サービスの停止）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              当社は、次の各号のいずれかに該当すると判断した場合、本サービスの全部又は一部を停止することができるものとします。
            </Text>
            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）天災等の不可抗力により本サービスの継続が困難になり又は困難になるおそれがある場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）本サービスの円滑な運営を維持するためにやむを得ず行う場合
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （3）その他運用上あるいは技術上の理由又は不測の事態により当社が本サービスの一時的な停止が必要と判断した場合
              </Text>
            </View>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              当社は、本条の措置をとったこと、又は本条の措置をとらないことに関し、利用者その他の第三者に対し、一切の責任を負わないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第24条（本サービスの変更）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              1.
              当社は、利用者の承認を得ることなく、本サービスの内容を変更し、又は本サービスを終了することができるものとします。
            </Text>

            <Spacer height={8} />
            <Text style={styles.text}>
              2.
              当社は、本条の措置をとったこと、又は本条の措置をとらないことに関し、利用者その他の第三者に対し、一切の責任を負わないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第25条（秘密保持）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              当社及び利用者は、本契約期間中及び終了後１年間は、本サービスに関連して知り得た相手方に関する営業上・技術上の情報（以下「秘密情報」といいます。）を
              開示された目的に限定して使用するとともに、厳格に管理し、相手方の事前の書面による承諾を得ることなしに、第三者（当社の関連会社を除きます）に開示しないものとします。
              但し、次の各号のいずれかに該当する場合はこの限りではありません。
            </Text>
            <Spacer height={8} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                （1）開示を受けた時点で既に公知であった情報、又は既に保有していた情報
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （2）開示を受けた後、当事者の責によらず公知となった情報
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （3）秘密保持義務を負うことなく正当な権利を有する第三者から正当に入手した情報
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （4）開示された情報によらずに独自に開発した情報
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （5）相手方が秘密保持義務を課すことなく第三者に開示した情報
              </Text>

              <Spacer height={8} />
              <Text style={styles.text}>
                （6）法律の定めに基づき裁判所その他官公庁より開示を求められた情報
              </Text>
            </View>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第26条（再委託）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              当社は、本サービスの提供にあたり、利用者に対する通知なく、またその承諾を得ることなく、当社の業務の全部又は一部を第三者に委託することができるものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第27条（損害賠償）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              利用者は、その帰責事由の有無にかかわらず、本規約に定める禁止行為を行ったこと、本規約に違反したことその他の事由により、当社又は他の利用者を含む第三者に損害を与え、
              あるいは何らかの費用を出損させた場合、その損害及び費用（訴訟費用、弁護士費用及び信用回復措置に要した費用等の全てを含みますが、それらに限られません。）を賠償又は補填するものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第28条（権利義務譲渡の禁止）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              利用者は、本規約に基づく権利義務、又は本規約上の地位を第三者に譲渡、承継その他一切の処分をすることができないものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第29条（連絡／通知）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              本サービスに関する問い合わせその他利用者から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から利用者に対する連絡又は通知は、当社の定める方法で行うものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第30条（合意管轄裁判所）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              本契約又は本サービスに関する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とするものとします。
            </Text>
          </View>

          <Spacer height={16} />
          <Text style={styles.section}>第31条（準拠法）</Text>
          <Spacer height={16} />

          <View style={styles.indent}>
            <Text style={styles.text}>
              本規約は、日本国内で有効に効力を有する法令に準拠するものとします。
            </Text>
          </View>
          <Spacer height={24} />
          <Text style={styles.text}>2021年9月1日 制定</Text>
        </Card>
        <Spacer height={36} />
      </Wrapper>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  content: {
    padding: 24,
    width: "100%",
  },
  section: {
    ...Fonts.xlr100,
  },
  text: {
    ...Fonts.lr130,
  },
  indent: {
    paddingLeft: 8,
  },
});
